import React, {useState} from 'react'
import { Container, Row, Col, Card, ListGroup, Carousel, Button, Form } from 'react-bootstrap'
import {Helmet} from "react-helmet";
//import { Button, Checkbox, Label, TextInput } from 'flowbite-react';
//import Link from 'next/link';

const seo = {
    title: 'Bela Azevedo',
    description: 'Aprenda a tocar ukulele de forma descomplicada em 7 passos.',
    url: '',
    image: '../img/uke.jpeg',
}


function Captura() {
    return (
        <>
        <Helmet
            title={`${seo.title}`}
            meta={[
                { name: 'description', property: 'og:description', content: seo.description },
                { property: 'og:title', content: `${seo.title}` },
                { property: 'og:url', content: seo.url },
                { property: 'og:image', content: seo.image },
                { property: 'og:image:type', content: 'image/jpeg' },
                { property: 'twitter:image:src', content: seo.image },
                { property: 'twitter:title', content: `${seo.title}` },
                { property: 'twitter:description', content: seo.description },
        ]} />

        <div className="aboutCapture">
            <Container>
                <Row style={{marginBottom: '50px', marginTop: '50px', alignItems: 'center'}} className="">
                    <Col sm>
                        <h2 className="titulo" style={{marginTop: '20px', color: "#fff"}}>Aprenda Ukulele em 7 passos.</h2>
                        <p className="paragraph3" style={{marginTop: '20px', marginBottom: '0px', textAlign: 'left', color: '#fff'}}>A Semana do Ukulele é um evento totalmente gratuito que vai te mostrar como sair do zero e aprender a tocar ukulele em 7 passos.</p>
                        <br></br>
                        <a href="#form"><button className='buttonSale2' style={{margin: '12px', marginBottom: '50px'}}>Inscrição gratuita</button></a>
                    </Col>
                    <Col>
                        <img className="pics pointo" style={{alignItems: 'center', marginBottom: '0px'}} src="../img/uk.jpeg" />
                    </Col>
                </Row>

                <h2 className='titulo2' style={{color: '#fff', marginBottom: '30px'}}>Quem é Bela Azevedo?</h2>
                <p className='paragraph3' style={{color: '#fff', marginBottom: '30px'}}>Cantora, professora de música, compositora e graduanda na licenciatura de música da Universidade Federal de Minas Gerais.</p>
            </Container>      
        </div>

        <div className="aboutCapture2" id='form'>
            <Container>
                <Row style={{marginBottom: '50px', marginTop: '50px', alignItems: 'center'}} className="">
                    <Col sm>
                        <h2 className="titulo2" style={{marginTop: '20px', color: "#0a0a0a"}}>Participe da Semana do Ukulele gratuitamente</h2>
                        <p className="paragraph3" style={{marginTop: '20px', marginBottom: '0px', textAlign: 'left', color: '#0a0a0a'}}>Três aulas ao vivo para te ensinar os 3 principais segredos para aprender ukulele de forma fácil e rápida para impressionar amigos e família.</p>
                    </Col>
                </Row>
            </Container>     
            <iframe width="100%" height="850px" src="https://14bdb2bc.sibforms.com/serve/MUIFAAHePobpgMDwqwQS5kZ9Y_8IejzXSmFih0-y_UcrM1lj3EthjxB7XxUrdKn9YxnKABzkPelWGALv38i9FJfH9fogDGEKq7vl6dsOrStFn7Bprrp2IPkhf3oHX5rwTfVkyFBidli2XPGqeGWcuSCvbKPjclu781YZpLdgfRytZ5-E8R_2ys8VanGRIGIM8Ds4nRTMnwc2DBub" frameborder="0" scrolling="auto" allowfullscreen style={{display: "block", marginLeft: "auto", marginRight: "auto", maxWidth: "100%", backgroundColor: '#fff'}}></iframe>                    
 
        </div>

        {/*<div className="pitch" id="#pitch">
            <Container style={{alignItems: 'center'}}>
                <Row style={{marginTop: '50px', marginBottom: '30px'}} className="">
                    <Col>
                        <img className="pics pointo" style={{alignItems: 'center'}} src="../img/ukulele2.jpg" alt="lâmpada" />
                    </Col>
                    <Col>
                        <div style={{alignItems: 'center'}}>
                            <br></br>
                            <h4 className="titulo3" style={{}} align="left">Aprenda a tocar ukulele em 1 semana</h4>
                            <br></br>
                            <p className="paragraph3">O programa Oi Ansiedade busca ensinar a todos a lidar com os
                            transtornos de ansiedade oferecendo a eles ferramentas através de técnicas
                            validadas pela ciência e pela prática clínica.</p>

                            <p className="paragraph3">O programa Oi Ansiedade busca ensinar a todos a lidar com os
                            transtornos de ansiedade oferecendo a eles ferramentas através de técnicas
                            validadas pela ciência e pela prática clínica.</p>

                            <a href="#form"><button className="buttonSale2" style={{marginTop: '10px', marginBottom: '30px'}}>Quero participar</button></a>

                        </div>
                    </Col>
                </Row>
            </Container>
    </div>*/}
        
    {/* <div className="about" id="#about">
            <Container>
                <Row style={{marginBottom: '100px', marginTop: '50px', alignItems: 'center', color: '#fff'}}>
                    <Col>
                        <img className="pics pointo pointo2" style={{alignItems: 'center'}} src="../img/mar.jpeg" alt="Márcio" />
                    </Col>
                    <Col>
                        <h2 className="titulo1" style={{marginTop: '20px', color: '#fff'}}>Quem é Márcio Azevedo?</h2>
                        <p className="paragraph3" style={{marginTop: '20px', marginBottom: '0px', textAlign: 'left', color: '#fff'}}>Teólogo formado pela Umesp (Universidade Metodista de
São Paulo), tendo pós graduação em neuro-psicopedagogia clínica e
Psicanalista pelo Centro de Estudos em Terapias e Psicanalise (CETEP –
Campinas, SP), Especialista em Transtornos de Ansiedade. Tendo mais 20
anos de experiência no tratamento e cuidado do ser humano.</p>
                        <br></br>
                        <a href="/about"><button style={{margin: '12px'}}>Saber mais</button></a>
                    </Col>
                </Row>
            </Container>
        </div>

        <div className="frontbanne">
        <Carousel style={{height: "90vh", width: "100%", marginBottom: '50px'}}>
                        <Carousel.Item>
                            <img
                            className="bob2"
                            src="../img/nature6.jpg"
                            alt="First slide"
                            />
                            <Carousel.Caption>
                            <div className="frontWords2">
                                <h1 className="titulo" style={{color: "#fff", fontSize: '60px', alignItems: 'center'}}>O deserto</h1>
                                <br></br>
                                <a href="https://pay.kiwify.com.br/G5brJ6a"><button className="buttonSale3">Fazer parte</button></a>
                            </div>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                            className="bob2"
                            src="../img/nature3.jpg"
                            alt="Second slide"
                            />
                            <Carousel.Caption>
                            <div className="frontWords2">
                                <h1 className="titulo" style={{color: "#fff", fontSize: '60px', alignItems: 'center'}}>A jornada</h1>
                                <br></br>
                                <a href="https://pay.kiwify.com.br/G5brJ6a"><button className="buttonSale3">Fazer parte</button></a>
                            </div>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                            className="bob2"
                            src="../img/nature4.jpg"
                            alt="Third slide"
                            />
                            <Carousel.Caption>
                            <div className="frontWords2">
                                <h1 className="titulo" style={{color: "#fff", fontSize: '60px', alignItems: 'center'}}>A realização</h1>
                                <br></br>
                                <a href="https://pay.kiwify.com.br/G5brJ6a"><button className="buttonSale3">Fazer parte</button></a>
                            </div>
                            </Carousel.Caption>
                        </Carousel.Item>
                    </Carousel>           
        </div>      

        <div className="pitch" id="#pitch">
            <Container style={{alignItems: 'center'}}>
                <Row style={{marginTop: '150px', marginBottom: '30px'}} className="">
                    <Col>
                    <br></br>
                    <h4 className="titulo3" style={{}} align="left">A jornada do programa que vai tratar sua ansiedade</h4>
                    <br></br>
                    <p className="paragraph3">O programa Oi Ansiedade é uma jornada que irá desenvolver na pessoa uma
consciência de que é possível entender onde estão os gatilhos que disparam
sua ansiedade.</p>
                    <p className="paragraph3">Este programa é formatado em uma jornada, um caminho em que seu usuário
terá atividades práticas, vídeos explicativos sobre técnicas para lidar com ansiedade além da meditação para serem realizadas ao
longo do dia e um encontro online semanal com o Psicanalista Marcio Azevedo a fim de
receber atenção especializada.</p>
                    <p className="paragraph3">Neste encontro online toda semana seus participantes receberam aulas sobre cada transtorno de Ansiedade.</p>
                    <br></br>
                    <a href="https://pay.kiwify.com.br/G5brJ6a"><button className="buttonSale2" style={{marginTop: '10px', marginBottom: '30px'}}>Quero fazer parte</button></a>
                    </Col>
                    <Col>
                        <img className="pics pointo" style={{alignItems: 'center'}} src="../img/caminho.jpg" alt="lâmpada" />
                    </Col>
                </Row>
            </Container>
        </div>     

        <div className="packages" id="#packages">
            <Container style={{marginBottom: '90px'}}>
                <Row style={{marginTop: '50px', marginBottom: '40px'}} className="mar">
                    <Col sm>
                        <h2 className="titulo1" style={{}}>Ainda tem dúvidas sobre o programa Oi Ansiedade?</h2>
                        <br></br>
                        <br></br>
                        <p className="paragraph3" style={{textAlign: 'center', margin: '12px'}}>Entre em contato conosco para tirar todas as suas dúvidas e entender melhor ainda como funciona o programa Oi Ansiedade e todos os benefícios que você pode ter ao fazer desse jornada contra a ansieade.</p>
                        <br></br>
                    </Col>
                </Row>
                <a href="https://wa.me/5532984829872?text=Ol%C3%A1%2C+M%C3%A1rcio%21+Gostaria+de+saber+mais+sobre+o+programa+Oi+Ansiedade%2C+poderia+me+enviar+um+%C3%A1udio+explicando+melhor%3F"><button className="buttonSale2" style={{margin: '12px'}}>Conversar conosco</button></a>
            </Container>
        </div> */}
        </>
    )
}

export default Captura;