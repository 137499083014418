import React from 'react'
import { Container, Row, Col, Card, ListGroup, Carousel } from 'react-bootstrap'
import {Helmet} from "react-helmet";

const seo = {
    title: 'Bela Azevedo',
    description: 'Aprenda a tocar ukulele de forma descomplicada em 7 passos.',
    url: '',
    image: '../img/uke.jpeg',
}


function Home() {
    return (
        <>
        <Helmet
            title={`${seo.title}`}
            meta={[
                { name: 'description', property: 'og:description', content: seo.description },
                { property: 'og:title', content: `${seo.title}` },
                { property: 'og:url', content: seo.url },
                { property: 'og:image', content: seo.image },
                { property: 'og:image:type', content: 'image/jpeg' },
                { property: 'twitter:image:src', content: seo.image },
                { property: 'twitter:title', content: `${seo.title}` },
                { property: 'twitter:description', content: seo.description },
        ]} />

        <div className="aboutCapture">
            <Container className="flex items-center sm:mt-4 h-screen mb-24">
                <Row className=''>
                    <Col>
                        <img className="pics pointo rounded-2xl mt-28 md:mt-2 lg:mt-2" style={{alignItems: 'center'}} src="../img/cape.png" alt="lâmpada" />
                    </Col>
                    <Col sm className='my-auto'>
                        <h2 className="titulo12 mt-8" style={{color: "#fff"}}>Método Descomplicando o Ukulele (MDU)</h2>
                        <p className="paragraph3" style={{marginBottom: '0px', textAlign: 'left', color: '#fff'}}>No MDU você vai aprender os 7 passos para sair do zero e começar a tocar ukulele com facilidade para impressionar amigos e família.</p>
                        <br></br>
                        <a href="https://pay.hotmart.com/X89601059K?bid=1705261401871" className='px-2 rounded-2xl'><button className="buttonSale23 rounded-2xl inline-flex px-4" style={{marginTop: '10px', marginBottom: '30px'}}>
                            Garantir minha vaga!
                            <svg class="ml-2 -mr-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                        </button></a>
                    </Col>
                </Row>
            </Container>      
        </div>

        <div className='bg-[#0a0a0a] text-white text-center items-center'>
            <Container>
                <div className='py-8 my-22'>
                    <h2 className='titulo2 text-white mb-16 sm:text-left md:text-center lg:text-center'>PROMOÇÃO DO MDU ATÉ ÀS 23:59 DE HOJE</h2>
                    <div className='md:grid lg:grid md:grid-cols-2 lg:grid-cols-2 gap-8 text-left  mb-10'>
                        <div>
                            <h2 className='titulo2 text-white text-2xl mb-4'>Uma mentoria para os 5 primeiros alunos</h2>
                            <p className='paragraph3 text-white text-left'>Uma mentoria individual de uma hora para os 5 primeiros alunos inscritos.</p>
                        </div>
                        <div>
                            <h2 className='titulo2 text-white text-2xl mb-4'>Promoção de R$697 por R$397</h2>
                            <p className='paragraph3 text-white text-left'>Até às 23:59 de hoje, o MDU estará por R$397 em até 12x de R$39,62</p>
                        </div>
                    </div>
                    <div>
                        <a href="https://pay.hotmart.com/X89601059K?bid=1705261401871" className='px-2 rounded-2xl'><button className="buttonSale23 rounded-2xl inline-flex px-4" style={{marginTop: '10px', marginBottom: '30px'}}>
                            Garantir minha mentoria!
                            <svg class="ml-2 -mr-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                        </button></a>
                    </div>
                </div>
            </Container>
        </div>

        <div className="pitch text-white" id="#pitch" style={{backgroundColor: '#BE626F'}}>
            <Container style={{alignItems: 'center'}}>
                <div className="gap-8 md:grid lg:grid lg:grid-cols-2 md:grid-cols-2 text-white mt-8 mb-16">
                    <div className='my-auto items-center'>
                    <br></br>
                    <h4 className="titulo2" style={{}} align="left">O método que vai te ensinar ukulele de uma vez por todas</h4>
                    <br></br>
                    <p className="paragraph3 text-white">O Método Descomplicando o Ukulele foi criado para te ensinar a sair do zero e aprender a tocar ukulele com fluência
                    , de forma fácil e didática.</p>
                    <p className="paragraph3 text-white">Com aulas teóricas e práticas nós iremos te ensinar todas as principais dicas e os 7 passos para aprender a tocar ukulele de forma rápida e fácil.</p>
                    <p className="paragraph3"></p>
                    <br></br>
                    <a href="https://pay.hotmart.com/X89601059K?bid=1705261401871"><button className="buttonSale23" style={{marginTop: '10px', marginBottom: '30px'}}>Quero fazer parte</button></a>
                    </div>
                    <div>
                        <img className="pics pointo rounded-2xl" style={{alignItems: 'center'}} src="../img/ble2.png" alt="lâmpada" />
                    </div>
                </div>
            </Container>
        </div>

        <Container className='text-center my-20'>
        <h2 className="titulo1 mb-16 text-[#BE626F]">O que eu ganho ao assinar o MDU?</h2>
        <div className='text-left mb-16'>
            <div className='md:grid lg:grid md:grid-cols-2 lg:grid-cols-2 gap-8'>
                <div className='mb-10 border-b-2 border-[#BE626F]'>
                    <h3 className='titulo2 text-black mb-6'>Mentorias em grupo ao vivo</h3>
                    <p className="paragraph3">Ao entrar para o MDU todos os alunos terão direito a participar de 1 mentoria em grupo por quinzenal durante os três primeiros meses depois da compra, totalizando 6 mentorias em grupo.</p>
                    <p className="paragraph3">Dessa forma será possível tirar dúvidas comuns, conversar com outros colegas que estão aprendendo ukulele, e melhorar as suas habilidades.</p>
                    <p className='mb-4 paragraph3'>As mentorias em grupo ficarão gravadas e serão disponibilizadas para assistir a qualquer momento dentro do MDU.</p>
                </div>
                <div className='mb-10 border-b-2 border-[#BE626F]'>
                    <h3 className='titulo2 text-black mb-6'>Uma mentoria individual para os cinco primeiros alunos.</h3>
                    <p className='paragraph3'>Os 5 primeiro alunos a se inscreverem no Método Descomplicando o Ukulele ganharão como bônus uma mentoria online individual que pode ser utilizada em até 6 meses.</p>
                    <p className='paragraph3 mb-4'>A mentoria individual pode ser utilizada para tirar dúvidas, entender como evoluir no aprendizado do ukulele e pedir dicas personalizadas para seu aprendizado.</p>
                </div>
                <div className='mb-10 border-b-2 border-[#BE626F]'>
                    <h3 className='titulo2 text-black mb-6'>Os 7 passos para tocar ukulele</h3>
                    <p className='paragraph3'>Os 7 passos para tocar ukulele são uma metodologia simples para aprender a sair do zero e tocar ukulele de forma descomplicada.</p>
                    <p className='paragraph3 mb-4'>Essa metodologia foi criada por Bela Azevedo para ajudar pessoas que estão começando no ukulele e não sabem como aprender.</p>
                </div>
                <div className='mb-10 border-b-2 border-[#BE626F]'>
                    <h3 className='titulo2 text-black mb-6'>Bônus: Um apêndice de músicas</h3>
                    <p className="paragraph3">Dentro do apêndice de músicas estarão tutoriais e materiais de cifra para tocar as músicas com facilidade.</p>
                    <p className="paragraph3 mb-4">O apêndice terá inúmeras músicas que serão atualizadas e muitas outras que serão incluidas todos os meses.</p>
                </div>
            </div>
        </div>
        <h2 className='titulo1 mb-12'>Quais são os 7 passos do MDU?</h2>
        <div className='justify-center align-center lg:grid gap-8 lg:grid-cols-3 md:grid md:grid-cols-2 text-center mx-auto'>
            <div className='p-4'>
                <img className="pics mx-auto rounded-full w-48 h-48" style={{marginBottom: '30px', marginTop: '0px'}} src="../img/passos/1.png" />
                <p className="text-2xl font-semibold text-gray-700" >Familiarização com o ukulele</p>
            </div>
            <div className='p-4'>
                <img className="pics mx-auto rounded-full w-48 h-48" style={{marginBottom: '30px', marginTop: '0px'}} src="../img/passos/2.png" />
                <p className="text-2xl font-semibold text-gray-700">Afinação do ukulele</p>
            </div>
            <div className='p-4'>
                <img className="pics mx-auto rounded-full w-48 h-48" style={{marginBottom: '30px', marginTop: '0px'}} src="../img/passos/3.png" />
                <p className="text-2xl font-semibold text-gray-700">Musicalidade no ukulele</p>
            </div>
            <div className='p-4'>
                <img className="pics mx-auto rounded-full w-48 h-48" style={{marginBottom: '30px', marginTop: '0px'}} src="../img/passos/4.png" />
                <p className="text-2xl font-semibold text-gray-700">Ciframento para ukulele</p>
            </div>
            <div className='p-4'>
                <img className="pics mx-auto rounded-full w-48 h-48" style={{marginBottom: '30px', marginTop: '0px'}} src="../img/passos/5.png" />
                <p className="text-2xl font-semibold text-gray-700">Coordenação motora</p>
            </div>
            <div className='p-4'>
                <img className="pics mx-auto rounded-full w-48 h-48" style={{marginBottom: '30px', marginTop: '0px'}} src="../img/passos/6.png" />
                <p className="text-2xl font-semibold text-gray-700">Execução das músicas</p>
            </div>
            <div className='p-4'>
                <img className="pics mx-auto rounded-full w-48 h-48" style={{marginBottom: '30px', marginTop: '0px'}} src="../img/passos/7.png" />
                <p className="text-2xl font-semibold text-gray-700">Prática das músicas</p>
            </div>
            <div className='p-4'>
                <img className="pics mx-auto rounded-full w-48 h-48" style={{marginBottom: '30px', marginTop: '0px'}} src="../img/passos/8.png" />
                <p className="text-2xl font-semibold text-gray-700">Bônus: Âpendice de músicas</p>
            </div>
        </div>
        <h1 className='titulo2 mt-12 text-black px-8'>Promoção até 23:59 de hoje por R$397,90 em até 12x.</h1>
        <br></br>
        <a href="https://pay.hotmart.com/X89601059K?bid=1705261401871"><button className="buttonSale23">Quero fazer parte</button></a>
        </Container>
        
        <div className="aboutCapture" id="#about">
            <Container>
                <div className='lg:grid md:grid lg:grid-cols-2 md:grid-cols-2 gap-8 align-center items-center' style={{marginBottom: '100px', marginTop: '50px', alignItems: 'center', color: '#fff'}}>
                    <div>
                        <img className="pics w-100 h-100 rounded-full" style={{alignItems: 'center'}} src="../img/bela.png" alt="Bela Azevedo" />
                    </div>
                    <div>
                        <h2 className="titulo1" style={{marginTop: '20px', color: '#fff'}}>Quem é Bela Azevedo?</h2>
                        <p className="paragraph3" style={{marginTop: '20px', marginBottom: '0px', textAlign: 'left', color: '#fff'}}>Cantora, professora de música, compositora e graduanda na licenciatura de música da Universidade Federal de Minas Gerais.</p>
                        <br></br>
                        <a href="/about"><button className='buttonSale23' style={{margin: '12px'}}>Saiber mais</button></a>
                    </div>
                </div>
            </Container>
        </div>

        {/*<div className="pitch" id="#pitch">
            <Container style={{alignItems: 'center'}}>
                <div style={{marginTop: '150px', marginBottom: '30px'}} className="gap-8 md:grid lg:grid md:grid-cols-2 lg:grid-cols-2">
                    <div>
                    <br></br>
                    <h4 className="titulo3" style={{}} align="left">A jornada do programa que vai tratar sua ansiedade</h4>
                    <br></br>
                    <p className="paragraph3">O programa Oi Ansiedade é uma jornada que irá desenvolver na pessoa uma
consciência de que é possível entender onde estão os gatilhos que disparam
sua ansiedade.</p>
                    <p className="paragraph3">Este programa é formatado em uma jornada, um caminho em que seu usuário
terá atividades práticas, vídeos explicativos sobre técnicas para lidar com ansiedade além da meditação para serem realizadas ao
longo do dia e um encontro online semanal com o Psicanalista Marcio Azevedo a fim de
receber atenção especializada.</p>
                    <p className="paragraph3">Neste encontro online toda semana seus participantes receberam aulas sobre cada transtorno de Ansiedade.</p>
                    <br></br>
                    <a href="https://pay.kiwify.com.br/G5brJ6a"><button className="buttonSale2" style={{marginTop: '10px', marginBottom: '30px'}}>Quero fazer parte</button></a>
                    </div>
                    <div>
                        <img className="pics pointo" style={{alignItems: 'center'}} src="../img/caminho.jpg" alt="lâmpada" />
                    </div>
                </div>
            </Container>
    </div>     */}

        <div className="packages" id="#packages">
            <Container style={{marginBottom: '90px'}}>
                <Row style={{marginTop: '50px'}} className="mar">
                    <Col sm>
                        <h2 className="titulo2 text-black" style={{}}>Ainda tem dúvidas sobre o MDU?</h2>
                        <p className="paragraph3" style={{textAlign: 'center', margin: '12px'}}>Entre em contato conosco para tirar todas as suas dúvidas.</p>
                        <br></br>
                    </Col>
                </Row>
                <a href="https://wa.me/5531975382592?text=Gostaria+de+falar+com+a+equipe+da+Bela+Azevedo+sobre+o+MDU."><button className="buttonSale2" style={{margin: '12px'}}>Converse conosco</button></a>
            </Container>
        </div>
        </>
    )
}

export default Home;