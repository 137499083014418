import React, {useState} from 'react'
import { Container, Row, Col, Card, ListGroup, Carousel, Button, Form } from 'react-bootstrap'
import {Helmet} from "react-helmet";
//import { Button, Checkbox, Label, TextInput } from 'flowbite-react';
//import Link from 'next/link';


function ThanksPage() {
    return (
        <>

        <div className="thanks">
            <Container>
                <Row style={{marginBottom: '50px', marginTop: '50px', alignItems: 'center'}} className="">
                    <Col sm style={{marginBottom: '50px'}}>
                        <h2 className="titulo" style={{marginTop: '20px', color: "#fff"}}>Quase lá!</h2>
                        <p className="paragraph3" style={{marginTop: '20px', marginBottom: '0px', textAlign: 'left', color: '#fff'}}>Para concluir totalmente a sua inscrição e participar gratuitamente da Semana do Ukulele entre na comunidade do Whatsapp.</p>
                        <br></br>
                        <a href="https://chat.whatsapp.com/DIo7EGm71e2JJNgPeMeM6y"><button className='buttonSale2' style={{margin: '12px'}}>Entrar no grupo</button></a>
                    </Col>
                    <Col>
                        <img className="pics pointo" style={{alignItems: 'center', marginBottom: '0px'}} src="../img/des.png" alt="lâmpada" />
                    </Col>
                </Row>
            </Container>      
        </div>
        </>
    )
}

export default ThanksPage;