const firebaseConfig = {
  apiKey: "AIzaSyAs--k1wlRuuUzTU05FX4_3LNJPDp-girY",
  authDomain: "oi-ansiedade.firebaseapp.com",
  projectId: "oi-ansiedade",
  storageBucket: "oi-ansiedade.appspot.com",
  messagingSenderId: "198835044617",
  appId: "1:198835044617:web:caba99469c05e867d653d8",
  measurementId: "G-96JPL7EE94"
};
  
export default firebaseConfig;