import React, { Redirect } from 'react';
import "./App.css";
import './styles/style.css';
import { BrowserRouter, Routes, Route, Navigate, Router } from 'react-router-dom';
import firebase, { FirebaseContext } from './firebase';
import useAuth from './authentication/useAuth';
import Home from './components/home';
import Header from './components/header';
import Contato from './components/contato';
import Footer from './components/footer';
import Captura from './components/captura';
import ThanksPage from './components/thankspage';

function App() {

  const user = useAuth();

  return (
    <BrowserRouter>
      <FirebaseContext.Provider value={{ user, firebase }}>
        <div>
          <Header />
          <div>
            <Routes>
              <Route exact path='/' element={ <Home /> } />
              <Route path='/contato' element={ <Navigate to='/ukulele' /> } />
              <Route path='/ukulele' element={ <Captura /> } />
              <Route path='/obrigado' element={ <ThanksPage /> } />
            </Routes>
          </div>
          <Footer />
        </div>
      </FirebaseContext.Provider>
    </BrowserRouter>
  );
}

export default App;
